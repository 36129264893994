import { createFilterURL } from '@/utils/urls'
import config from '@/config'

const axios = config.urls.AXIOS_API

export default function useBasicApi(ENDPOINT, includeFuncs = []) {
  const get = async ({ filter = {}, options = {} } = {}) =>
    axios.get(createFilterURL(`${ENDPOINT}/`, filter), options)

  const getNext = async ({ url, options = {} }) => axios.get(url, { ...options })

  const remove = async () => axios.delete(`${ENDPOINT}/`)

  const DEFAULT_FUNCS = {
    ENDPOINT,
    get,
    getNext,
    remove,
  }

  return Object.fromEntries(
    Object.entries(DEFAULT_FUNCS).filter(([key]) => includeFuncs.includes(key))
  )
}
